import remStylisPlugin from '@shared/utils/remStylisPlugin';
import ReactDom from 'react-dom/client';
import { StyleSheetManager } from 'styled-components';

class EntrypointElement extends HTMLElement {
  reactComponent = null;

  async hydrateReact(componentName: string) {
    const Component = (await import(`../components/${componentName}/index.tsx`))?.default;
    if (!Component) return;

    const props = JSON.parse(this.getAttribute('props'));

    ReactDom.hydrateRoot(
      this,
      <StyleSheetManager stylisPlugins={[remStylisPlugin]}>
        <Component {...props} />
      </StyleSheetManager>,
    );
  }

  connectedCallback() {
    if (!this.isConnected) return;
    const componentName = this.getAttribute('component');

    if (componentName) {
      this.hydrateReact(componentName).catch(e => {
        console.error(e);
      });
    }
  }
}

customElements.define('d3-server-component', EntrypointElement);
