import { DECLARATION } from 'stylis';

export interface Element {
  parent: Element | null;
  children: Element[] | string;
  root: Element | null;
  type: string;
  props: string[] | string;
  value: string;
  length: number;
  return: string;
  line: number;
  column: number;
}

const remStylisPlugin = (element: Element): string | void => {
  if (element.type === DECLARATION) {
    const newContent = element.value.replace(/(\d+(\.\d+)?)rem/g, (match, value) => parseFloat(value) / 1.6 + 'rem');

    element.return = newContent;
  }
};

export default remStylisPlugin;
